.underContruction{
    width: 100%;
    height: 100%;
    background:url(../../../public/Assets/christian-life-crisis-prayer-god.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo{
    position: absolute;
    top: 30px;
    left: 30px;
    width: 100px
}
.info1{
    font-size: 4vw;
    color: white;
    font-weight: 800;
    text-shadow: black 5px 0 20px;
    display: flex;
}

.info2{
    font-size: 3vw;
    color: white;
    font-weight: 800;
    text-shadow: black 5px 0 20px;
}
.shade{
    top:0;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: grey;
    opacity: 0.3;
}

.opening{
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    animation: fadeOutOpacity 5s;
    opacity: 0;
    object-fit: cover;

}

@keyframes fadeOutOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@media only screen and (max-width: 600px) {
    .info1 {
        font-size: 40px;
        flex-direction: column;

    }
    .info2{
        font-size: 35px;

    }
    .logo{
        width: 80px;
    }
  }